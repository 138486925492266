'use strict';

angular.module('mvpcarrefourApp')
    .factory('Modal', function($rootScope, $uibModal, $location, $sce) {
        /**
         * Opens a modal
         * @param  {Object} scope      - an object to be merged with m,odal's scope
         * @param  {String} modalClass - (optional) class(es) to be applied to the modal
         * @return {Object}            - the instance $uibModal.open() returns
         */
        function openModal(scope = {}, modalClass = 'modal-default') {
            var modalScope = $rootScope.$new();
            angular.extend(modalScope, scope);
            return $uibModal.open({
                templateUrl: 'components/modal/modal.html',
                windowClass: modalClass,
                scope: modalScope
            });
        }

        /**
         * Opens a modal Widget
         * @param  {Object} scope      - an object to be merged with m,odal's scope
         * @param  {String} modalClass - (optional) class(es) to be applied to the modal
         * @return {Object}            - the instance $uibModal.open() returns
         */
        function openModalWidget(scope = {}, modalClass = 'modal-default') {
            var modalScope = $rootScope.$new();
            angular.extend(modalScope, scope);
            return $uibModal.open({
                templateUrl: 'components/modal/modal-widget-options.html',
                windowClass: modalClass,
                scope: modalScope,
                  controller: function($rootScope, $uibModalInstance,widgetUrl) {                 
                    
                    $rootScope.close = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                  
                    $rootScope.widgetUrl=widgetUrl;                      
                                
                },resolve: {
                    widgetUrl: function() {
                        return modalScope.modal.widgetUrl;
                    }
                }
            });
        }

        /**
         * Abre un modal ocupando el 100% de la pantalla.
         * @param  {Object} scope      - an object to be merged with modal's scope
         * @param  {String} modalClass - (optional) class(es) to be applied to the modal
         * @return {Object}            - the instance $uibModal.open() returns
         */
        function openModalFull(scope = {}, modalClass = 'modal-full mobile') {
            var modalScope = $rootScope.$new();
            angular.extend(modalScope, scope);
            return $uibModal.open({
                templateUrl: 'components/modal/mbl-fullModal.html',
                windowClass: modalClass,
                scope: modalScope,
                controller: function($rootScope, $uibModalInstance, list, actual, $location) {
                    $rootScope.list = list;
                    $rootScope.selected = {
                        item: $rootScope.list[actual]
                    };

                    // Salir sin seleccionar
                    $rootScope.back = function() {
                        $uibModalInstance.dismiss('cancel');
                    };

                    //Despues de seleccionar categoria
                    $rootScope.goBack = function() {
                        // ¿ Podria cerrarse antes de resolver el selected ?????
                        $uibModalInstance.dismiss('cancel');
                    };
                },
                resolve: {
                    list: function() {
                        return modalScope.modal.list;
                    },
                    actual: function() {
                        return modalScope.modal.actual;
                    }
                }
            }).result.finally(function() {

               $location.path('/votacion/' + $rootScope.selected.item.slug);

            });
        }

        // Public API here
        return {

            /* Confirmation modals */
            confirm: {

                /**
                 * Create a function to open a delete confirmation modal (ex. ng-click='myModalFn(name, arg1, arg2...)')
                 * @param  {Function} del - callback, ran when delete is confirmed
                 * @return {Function}     - the function to open the modal (ex. myModalFn)
                 */
                delete(del = angular.noop) {
                    /**
                     * Open a delete confirmation modal
                     * @param  {String} name   - name or info to show on modal
                     * @param  {All}           - any additional args are passed straight to del callback
                     */
                    return function() {
                        var args = Array.prototype.slice.call(arguments),
                            name = args.shift(),
                            deleteModal;

                        deleteModal = openModal({
                            modal: {
                                dismissable: true,
                                title: 'Proceso de eliminacion',
                                html: '<p>Está seguro que desea eliminar <strong>' + name + '</strong> ?</p>',
                                buttons: [{
                                    classes: 'btn-danger',
                                    text: 'Delete',
                                    click: function(e) {
                                        deleteModal.close(e);
                                    }
                                }, {
                                    classes: 'btn-default',
                                    text: 'Cancel',
                                    click: function(e) {
                                        deleteModal.dismiss(e);
                                    }
                                }]
                            }
                        }, 'modal-danger');

                        deleteModal.result.then(function(event) {
                            del.apply(event, args);
                        });
                    };
                },
                /**
                 * Create a function to open a deliver confirmation modal (ex. ng-click='myModalFn(name, arg1, arg2...)')
                 * @param  {Function} del - callback, ran when deliver is confirmed
                 * @return {Function}     - the function to open the modal (ex. myModalFn)
                 */
                deliver(del = angular.noop) {
                    /**
                     * Open a deliver confirmation modal
                     * @param  {String} name   - name or info to show on modal
                     * @param  {All}           - any additional args are passed straight to del callback
                     */
                    return function() {
                        var args = Array.prototype.slice.call(arguments),
                            name = args.shift(),
                            deliverModal;

                        deliverModal = openModal({
                            modal: {
                                dismissable: true,
                                title: 'Confirmar envío',
                                html: '<p>Realmente desea enviar  <strong>' + name + '</strong> ?</p>',
                                buttons: [{
                                    classes: 'btn-success',
                                    text: 'Deliver',
                                    click: function(e) {
                                        deliverModal.close(e);
                                    }
                                }, {
                                    classes: 'btn-default',
                                    text: 'Cancel',
                                    click: function(e) {
                                        deliverModal.dismiss(e);
                                    }
                                }]
                            }
                        }, 'modal-success');

                        deliverModal.result.then(function(event) {
                            del.apply(event, args);
                        });
                    };
                },
                /**
                 * Create a function to open a deliver confirmation modal (ex. ng-click='myModalFn(name, arg1, arg2...)')
                 * @param  {Function} del - callback, ran when deliver is confirmed
                 * @return {Function}     - the function to open the modal (ex. myModalFn)
                 */
                process(del = angular.noop) {
                    /**
                     * Open a deliver confirmation modal
                     * @param  {String} name   - name or info to show on modal
                     * @param  {All}           - any additional args are passed straight to del callback
                     */
                    return function() {
                        var args = Array.prototype.slice.call(arguments),
                            name = args.shift(),
                            deliverModal;

                        deliverModal = openModal({
                            modal: {
                                dismissable: true,
                                title: 'Confirmar proceso',
                                html: '<p>Está seguro que desea procesar <strong>' + name + '</strong> ?</p>',
                                buttons: [{
                                    classes: 'btn-success',
                                    text: 'Process',
                                    click: function(e) {
                                        deliverModal.close(e);
                                    }
                                }, {
                                    classes: 'btn-default',
                                    text: 'Cancel',
                                    click: function(e) {
                                        deliverModal.dismiss(e);
                                    }
                                }]
                            }
                        }, 'modal-success');

                        deliverModal.result.then(function(event) {
                            del.apply(event, args);
                        });
                    };
                },
              info(options, del) {
                /**
                 * Open a deliver confirmation modal
                 * @param  {String} name   - name or info to show on modal
                 * @param  {All}           - any additional args are passed straight to del callback
                 */
                return function() {
                  var args = Array.prototype.slice.call(arguments),
                    obj = args.shift(),
                    err = obj.data ? obj.data.errmsg : "",
                    deliverModal;

                  deliverModal = openModal({
                    modal: {
                      dismissable: true,
                      title: options.title || 'Confirm Processing',
                      html: '<p>' + options.msg + ' ' + err || '' + '</p>',
                      buttons: [{
                        classes: 'btn-success',
                        text: 'Aceptar',
                        click: function(e) {
                          deliverModal.close(e);
                        }
                      }]
                    }
                  }, err ? 'modal-danger' : 'modal-primary');

                  deliverModal.result.then(function(event) {
                    del.apply(event, args);
                  });
                };
              },
              widget(options, del) {
                /**
                 * Open a deliver confirmation modal
                 * @param  {String} name   - name or info to show on modal
                 * @param  {All}           - any additional args are passed straight to del callback
                 */
                return function() {
                  var args = Array.prototype.slice.call(arguments),
                    obj = args.shift(),
                    err = obj != null && obj.data ? obj.data.errmsg : "",
                    deliverModal;

                  deliverModal = openModalWidget({
                    modal: {
                      dismissable: true,
                      title: '',
                      widgetUrl: options.widgetUrl                     
                    }
                  }, err ? 'modal-danger' : 'modal-primary');

                  deliverModal.result.then(function(event) {
                    del.apply(event, args);
                  });
                };
              }
            },
            full: {

                all(all, def) {

                    return function() {
                        var args = Array.prototype.slice.call(arguments),
                            name = args.shift(),
                            openFullModal;

                        openFullModal = openModalFull({
                            modal: {
                                dismissable: true,
                                title: '',
                                list: all,
                                actual: def
                            }
                        });

                        // openFullModal.result.then(function(event) {
                        //     all.apply(event, args);
                        //     console.log('args ', args);
                        // });

                    };
                }


            }
        };
    });

